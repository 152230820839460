import React from 'react';

export const Tutorial0DataSource = {
  wrapper: { className: 'home-page-wrapper tutorialview-wrapper' },

  content: {
    className: 'tutorial-menu',
    children:[{
      className: 'tutorial-menu1',
      nenuname:"出版题本",
      children:[{
        className: 'tutorial-menu2',
        nenuname:"新建题本",
      },{
        className: 'tutorial-menu2',
        nenuname:"添加题目",
      },{
        className: 'tutorial-menu2',
        nenuname:"添加章节",
      },{
        className: 'tutorial-menu2',
        nenuname:"申请发布",
      }]
    },
      {
        className: 'tutorial-menu1',
        nenuname:"参加统考",
        children:[{
          className: 'tutorial-menu2',
          nenuname:"熟悉题目",
        },{
          className: 'tutorial-menu2',
          nenuname:"参加测验",
        }]
      },
      {
        className: 'tutorial-menu1',
        nenuname:"达成合约",
        children:[{
          className: 'tutorial-menu2',
          nenuname:"创建合约",
        },{
          className: 'tutorial-menu2',
          nenuname:"邀请达成",
        }]
      }],
  },
};


