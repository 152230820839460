import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col, List, Avatar, Icon} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

function Content6(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (

        <List
            itemLayout="vertical"
            size="large"
            pagination={{
                onChange: page => {
                    console.log(page);
                },
                pageSize: 6,
            }}
            dataSource={dataSource.listdata.children}
            renderItem={item => (
                <List.Item
                    key={item.title}
                    className={'content6-item'}

                    extra={
                        <img

                            width={300}
                            height={180}
                            alt="logo"
                            src={"http://slako-buildqst.stor.sinaapp.com/platform/img/slakoarticle/"+item.id+'/1.jpg'}
                        />
                    }
                >
                    <List.Item.Meta

                        title={<a href={item.href}>{item.title}</a>}
                        description={item.description}
                    />
                    {item.content}
                </List.Item>
            )}
        />

  );
}

export default Content6;
