import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'http://slako-buildqst.stor.sinaapp.com/platform/logo/icon-76.png',
  },
  sitename:{
    className: 'header0-sitename',
    text:'积问累识'
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/home',
          children: [
            {
              children: (
                <>
                  <p>首页</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '/tutorial',
          children: [
            {
              children: (
                <>
                  <p>教程</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '/example',
          children: [
            {
              children: (
                <>
                  <p>示例</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '/article',
          children: [
            {
              children: (
                <>
                  <p>文章</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item4',
        className: 'header0-item',
        children: {
          href: '/qrcode',
          children: [
            {
              children: (
                  <>
                    <p>下载</p>
                  </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item5',
        className: 'header0-item',
        children: {
          href: '/uploadsomeqst',
          children: [
            {
              children: (
                  <>
                    <p>批量上传题目</p>
                  </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item~k08r487ghub',
        className: 'header0-item',
        children: {
          href: '/about',
          children: [
            {
              children: (
                <>
                  <p>关于</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};

