import React, { Component } from 'react';
import { BrowserRouter ,HashRouter, Route, Link } from "react-router-dom";
import Header from "./Nav/Nav0";
import Footer from "./Footer/Footer0";
import {Nav00DataSource} from "./Nav/data.source";
import {Footer00DataSource} from "./Footer/data.source";

import Home from "./Home/Home";
import About from "./About/About";
import Article from "./Article/Article";
import Tutorial from "./Tutorial/Tutorial";
import Example from "./Example/Example";
import QRcode from "./QRcode/QRcode";
import UploadSomeQst from "./UploadSomeQst/UploadSomeQst";
import ArticleMarkdown from "./ArticleMarkdown/ArticleMarkdown";
import PlatformArticle from "./PlatformArticle/PlatformArticle";

class App extends Component {

    render() {
        return (
            <BrowserRouter basename="/jwlsweb">
                <div>
                    <Header dataSource={Nav00DataSource} isMobile={this.isMobile} />
                    <Route  path="/home" component={Home} />
                    <Route  path="/about" component={About} />
                    <Route  path="/article" component={Article} />
                    <Route  path="/tutorial" component={Tutorial} />
                    <Route  path="/example" component={Example} />
                    <Route  path="/qrcode" component={QRcode} />
                    <Route  path="/uploadsomeqst" component={UploadSomeQst} />
                    <Route  path="/articlemarkdown/:mdid" component={ArticleMarkdown} />
                    <Route  path="/platformarticle/:mdid" component={PlatformArticle} />
                    <Footer dataSource={Footer00DataSource} isMobile={this.isMobile} />
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
