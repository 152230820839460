import React from 'react';

export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper about-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 8, xs: 24 },
  img: {
    children: 'http://slako-buildqst.stor.sinaapp.com/platform/img/website/jiwenleishishimo-2.png',
  },
  textWrapper: { className: 'content1-text', md: 16, xs: 24 },
  title: { className: 'content1-title', children: '永远免费的效率类学习软件' },
  content: {
    className: 'content1-content',
    children:
      '学习新知识就像谈恋爱，过程充满了兴奋和期待，学完了要复习，就像结婚后，平淡但重要。如果总是徘徊在恋爱阶段，又如何能开花结果？积问累识为复习而生。\n' +
        '知识大爆炸时代，创新的机会也越来越多，特别是互联网+，只要有一个善于做数据融合的头脑，融合的前提是头脑中稳稳地记得相关知识，积问累识为记忆而生。\n' +
        '对于知识的掌握程度判断，答题是从古至今历来的操作方法，题目形式多种多样，单选、多选、判断、填空是最基本的题型，从而延伸更多，积问累识为建题而生。\n' +
        '艾宾浩斯遗忘曲线鲜有人知，把该研究成果落地使用，一直比较模糊，与学习软件结合起来才能充分发挥优势，致力于提高掌握知识的效率，积问累识为效率而生。\n' +
        '通过分享图书、图片、语音、视频来传播学术是让更多的人智慧起来，多情景输入，使得知识更稳固地着床在大脑，分享题本也将登上舞台，积问累识为分享而生。\n' +
        '我需要学识，刚好你懂；我需要熟练度，刚好你够；我设置测验，刚好你满分；我这办公用人；刚好你有空。找工作更合适，找员工更准确，积问累识为遇见而生。\n' +
        '成功不可简单复制，但可以一路追随。他学什么，我也学什么，他学多深，我也学多深，他什么时候学，我也什么时候学。站在巨人肩膀上，积问累识为追随而生。\n' +
        '写书有多人联合创作，出题也有集思广议。相同的答案，不同角度问法。走的人多了，路就显现出来，多人审阅，去繁从简，共建知识标准，积问累识为共创而生。\n' +
        '答题满分是一种成就，建题无数也是，提题修改建议、出版题本等等都是成就，成就虽小，但代表人生中的各种角度，成就感增强学习信心，积问累识为成就而生。',
  },
};


