import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col ,Menu, Icon ,Layout} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {getChildrenToRender} from "../utils";
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
class TutorialView extends React.PureComponent {

    getSubMenuChildren = (id,data) =>
        data.map((item, i) => {
            //const { titleWrapper, ...$item } = item;
            return (
                    <Menu.Item key={(id*10+i).toString()}>{item.nenuname}</Menu.Item>
            );
        });
    getMenuChildren = (data) =>
        data.map((item, i) => {
            const { titleWrapper, ...$item } = item;
            return (
                <SubMenu
                    key={i.toString()}
                    title={
                        <span>
                        <span>{item.nenuname}</span>
                        </span>
                    }
                >
                    {this.getSubMenuChildren(i,item.children)}

                </SubMenu>
            );
        });

    render() {
        const { ...props } = this.props;
        const { dataSource } = props;
        delete props.dataSource;
        delete props.isMobile;
        //const listChildren = this.getMenuChildren(dataSource.children);
        return (
            <div {...props} {...dataSource.wrapper}>
                <Layout height={800}>
                    <Sider width={256} height={800} style={{ background: '#fff' }}>
                        <Menu
                            onClick={this.handleClick}
                            style={{ width: 256 ,height:800 }}
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            mode="inline"
                        >
                            {this.getMenuChildren(dataSource.content.children)}
                        </Menu></Sider>
                    <Layout style={{ padding: '0 24px 24px' }}>
                        <Content
                            style={{
                                background: '#fff',
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        >
                            Content
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

export default TutorialView;
