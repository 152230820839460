import React from 'react';

export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <span>
          ©2019 积问累识 深圳循律省时科技有限公司 All Rights
          Reserved 粤ICP备19128496号 <a href="./platformarticle/4">隐私政策</a>
        </span>
      </>
    ),
  },
};
