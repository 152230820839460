import React from 'react';
export const Teams20DataSource = {
  wrapper: { className: 'home-page-wrapper teams2-wrapper' },
  page: { className: 'home-page teams2' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '示例题本' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 3,
        xs: 30,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams2-image',
              children:
                  'http://slako-buildqst.stor.sinaapp.com/platform/img/qrcode/ios.png',
            },
            { name: 'title', className: 'teams2-title', children: '苹果 iOS' },
            {
              name: 'content',
              className: 'teams2-job',
              children: '2018-06-14',
            },

          ],
        },
      },
      {
        name: 'block0',
        className: 'block',
        md: 3,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams2-image',
              children:
                  'http://slako-buildqst.stor.sinaapp.com/platform/img/qrcode/ios.png',
            },
            { name: 'title', className: 'teams2-title', children: '苹果 iOS' },
            {
              name: 'content',
              className: 'teams2-job',
              children: '2018-06-14',
            },

          ],
        },
      },

    ],
  },
  childrentemplate:{
    name: 'block',
    className: 'block',
    md: 3,
    xs: 30,
    titleWrapper: {
      children: [
        {
          name: 'image',
          className: 'teams2-image',
          children:
              'http://slako-buildqst.stor.sinaapp.com/platform/img/qrcode/ios.png',
        },
        { name: 'title', className: 'teams2-title', children: '苹果 iOS' },
        {
          name: 'content',
          className: 'teams2-job',
          children: '2018-06-14',
        },

      ],
    },
  }
};
