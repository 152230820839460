import React from 'react';
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '各平台二维码' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'http://slako-buildqst.stor.sinaapp.com/platform/img/qrcode/ios.png',
            },
            { name: 'title', className: 'teams1-title', children: '苹果 iOS' },
            {
              name: 'content',
              className: 'teams1-job',
              children: '2018-06-14',
            },

          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'http://slako-buildqst.stor.sinaapp.com/platform/img/qrcode/android.png',
            },
            { name: 'title', className: 'teams1-title', children: '安卓 Android' },
            {
              name: 'content',
              className: 'teams1-job',
              children: '2019-09-09',
            },

          ],
        },
      },

    ],
  },
};
