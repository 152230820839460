/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import {
  Teams20DataSource
} from './data.source';
import '../less/antMotionStyle.less';

import Teams2 from "./Teams2";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

let httpsBaseUrl = "http://slako-buildqst.stor.sinaapp.com/";

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      netbooklist:Teams20DataSource
    };
    this.dofetch_booklist();
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */


  }

  converData(webdata){

    let newchild =[];
    let newData = JSON.parse(JSON.stringify(Teams20DataSource));
    webdata.forEach((item,index)=>{
      let childrentemplate = JSON.parse(JSON.stringify(Teams20DataSource.childrentemplate));
      childrentemplate.name = 'block'+index;
      childrentemplate.titleWrapper.children[0].children = httpsBaseUrl+item.cover;
      childrentemplate.titleWrapper.children[1].children = item.bookname;
      newchild.push(childrentemplate);
    });

    newData.block.children = newchild;
    //alert(JSON.stringify(newData));
    return newData;
  }

  dofetch_booklist(){

    let url = "http://slako-buildqst.stor.sinaapp.com/public/website/booklist";
    let opts = {
      method:"GET"
    };
    fetch(url,opts)
        .then((response) => response.json())
        .then((responseData) => {
          responseData.reverse();
          //alert("hello2");
          this.setState({
            netbooklist:this.converData(responseData)
          });
          //alert("hello");
        })
        .catch((error) => {
          //alert("eerr  hello23"+error);
        })
  }

  render() {
    const children = [
      <Teams2
          id="Teams2_0"
          key="Teams2_0"
          dataSource={this.state.netbooklist}
          isMobile={this.state.isMobile}
      />,
    ];
    return (
        <div
            className="templates-wrapper"
            ref={(d) => {
              this.dom = d;
            }}
        >
          {/* 如果不是 dva 2.0 替换成 {children} start */}
          {this.state.show && children}
          {/* 如果不是 dva 2.0 替换成 {children} end */}
        </div>
    );
  }
}
