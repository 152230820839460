import React from 'react';

export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: 'http://slako-buildqst.stor.sinaapp.com/platform/img/website/jiwenleishishimo-2.png',
  },
  content: {
    className: 'banner0-content',
    children: '一个知识量化分享平台',
  },
  button: { className: 'banner0-button', children: '下载地址' ,href: './qrcode',},
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>核心功能</p>
          </>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '制造题本',
            },
            { name: 'content', children: '单选、多选、填空、排序、判断等多种题型' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '达成合约',
            },
            {
              name: 'content',
              children: '可自由创建合约内容、规约，按时按质达成合约',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '规律复习',
            },
            {
              name: 'content',
              children: '根据艾宾浩斯遗忘曲线合理安排复习',
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                  'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '定时统考',
            },
            {
              name: 'content',
              children: '持续地制约着知识遗忘',
            },
          ],
        },
      },
      {
        name: 'block4',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                  'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '巨人肩膀',
            },
            {
              name: 'content',
              children: '在别人的题本上追加自己的题',
            },
          ],
        },
      },
      {
        name: 'block5',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                  'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '分享美好',
            },
            {
              name: 'content',
              children: '题本、题目、成绩、合约等等皆可分享',
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: '企业资源管理' },
  content: {
    className: 'content1-content',
    children:
      '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '永远免费的效率类学习软件',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '且无内购且无游戏广告',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '为复习而生' },
          content: {
            className: 'content3-content',
            children:
              '学习新知识就像谈恋爱，过程充满了兴奋和期待，学完了要复习，就像结婚后，平淡但重要。如果总是徘徊在恋爱阶段，又如何能开花结果？',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '为效率而生' },
          content: {
            className: 'content3-content',
            children:
              '艾宾浩斯遗忘曲线鲜有人知，把该研究成果落地使用，一直比较模糊，与学习软件结合起来才能充分发挥优势，致力于提高掌握知识的效率。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '为分享而生' },
          content: {
            className: 'content3-content',
            children:
              '通过分享图书、图片、语音、视频来传播学术是让更多的人智慧起来，多情景输入，使得知识更稳固地着床在大脑，分享题本也将登上舞台。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '为遇见而生' },
          content: {
            className: 'content3-content',
            children:
              '我需要学识，刚好你懂；我需要熟练度，刚好你够；我设置测验，刚好你满分；我这办公用人；刚好你有空。找工作更合适，找同志更准确，。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '为追随而生' },
          content: {
            className: 'content3-content',
            children:
              '成功不可简单复制，但可以一路追随。他学什么，我也学什么，他学多深，我也学多深，他什么时候学，我也什么时候学。站在巨人肩膀上。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '为共创而生' },
          content: {
            className: 'content3-content',
            children:
              '写书有多人联合创作，出题也有集思广议。相同的答案，不同角度问法。走的人多了，路就显现出来，多人审阅，去繁从简，共建知识标准。',
          },
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '支持语言', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: '已经支持8种语言，预计支持到10种语言',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'http://slako-buildqst.stor.sinaapp.com/platform/img/language/chinese.png',
          },
          content: { children: '中文 Chinese' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'http://slako-buildqst.stor.sinaapp.com/platform/img/language/english.png',
          },
          content: { children: '英语 English' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'http://slako-buildqst.stor.sinaapp.com/platform/img/language/russian.png',
          },
          content: { children: '俄语 Russian' },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'http://slako-buildqst.stor.sinaapp.com/platform/img/language/french.png',
          },
          content: { children: '法语 French' },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'http://slako-buildqst.stor.sinaapp.com/platform/img/language/german.png',
          },
          content: { children: '德语 German' },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'http://slako-buildqst.stor.sinaapp.com/platform/img/language/korean.png',
          },
          content: { children: '韩语 Korean' },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'http://slako-buildqst.stor.sinaapp.com/platform/img/language/Spanish.png',
          },
          content: { children: '西班牙语 Spanish' },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'http://slako-buildqst.stor.sinaapp.com/platform/img/language/Portuguese.png',
          },
          content: { children: '葡萄牙语 Portuguese' },
        },
      },
    ],
  },
};

