/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import {
  Content10DataSource
} from './data.source';
import '../less/antMotionStyle.less';
import Content6 from './Content6';
import {Teams20DataSource} from "../Example/data.source";
import Markdown from 'react-markdown';
import { Button, Radio, Icon } from 'antd';


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

export default class ArticleMarkdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };

  }
  converData(webdata,mdid){

    let newData = webdata;
    let replacepictext = newData.replace('[[pic1]]',"\n![](http://slako-buildqst.stor.sinaapp.com/platform/img/slakoarticle/"+ mdid + "/1.jpg)\n");
    replacepictext = replacepictext.replace('[[pic2]]',"\n![](http://slako-buildqst.stor.sinaapp.com/platform/img/slakoarticle/"+ mdid + "/2.jpg)\n");
    replacepictext = replacepictext.replace('[[pic3]]',"\n![](http://slako-buildqst.stor.sinaapp.com/platform/img/slakoarticle/"+ mdid + "/3.jpg)\n");

    return replacepictext;
  }

  dofetch_article(mdid){

    let url = "http://slako-buildqst.stor.sinaapp.com/platform/slakoarticle/zh/"+mdid+"/markdown.text";
    let opts = {
      method:"GET"
    };
    fetch(url,opts)
        .then((response) => response.text())
        .then((responseData) => {

          this.setState({
            articlemd:this.converData(responseData,mdid)
          });
          //alert("hello");
        })
        .catch((error) => {
          //alert("eerr  hello23"+error);
        })
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    this.dofetch_article(this.props.match.params.mdid);
  }
  //this.props.history.goBack();

  render() {
    const children = [
      <div className="articlemd-wrapper" >
        {/*<Button type="primary"*/}
                {/*onClick={()=>{this.props.history.goBack()}}*/}
        {/*>*/}
          {/*<Icon type="left" />*/}
          {/*返回*/}
        {/*</Button>*/}
        <Markdown source={this.state.articlemd} />
      </div>
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && this.state.articlemd && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
