/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import QRCode from 'qrcode.react';
import { Upload, Button, message, Avatar} from 'antd';
//import { UploadOutlined } from '@ant-design/icons';
import { PlusOutlined, UploadOutline } from '@ant-design/icons';
import reqwest from 'reqwest';

import {
  Content1DataSource
} from './data.source';
import '../less/antMotionStyle.less';
import Content1 from './Content1';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

export default class UploadSomeQst extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      auth:null,
      head:null,
      username:null,
      qrcode:null,
      fileList: [],
      uploading: false,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
    this.checkauthfile_time = 10;
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    if(this.state.qrcode == null){
      this.applyAuthQrCode();
    }
  }

  applyAuthQrCode(){
    //let url = "http://www.jiwenleishi.com/index.php?m=website&c=userauth&a=public_applyloginqrcode";
    let url = "/index.php?m=website&c=userauth&a=public_applyloginqrcode";
    let opts = {
      method:"GET"
    };
    fetch(url,opts)
        //.then((response) => response.text())
        .then((response) => response.json())
        .then((responseData) => {
          if(responseData.code ==100){
            this.setState({
              qrcode:responseData.data
            });
            this.checkIfLoginDone();
          }         
        })
        .catch((error) => {
        })
  }

  getAuthByQrCode(){
    console.log("getAuthByQrCode qrcode:"+this.state.qrcode);
    let url = 'http://storage.jiwenleishi.com/platform/websitelogin/qrcode/'+this.state.qrcode;
    let opts = {
      method:"GET"
    };
    fetch(url,opts)
        //.then((response) => response.text())
        .then((response) => response.json())
        .then((responseData) => {
          console.log("getAuthByQrCode responseData:",responseData);
            this.setState({
              auth:responseData.auth,
              head:responseData.head,
              username:responseData.username,
            });      
        })
        .catch((error) => {
          console.log("getAuthByQrCode qrcode catch error:"+error);
          if(this.state.auth == null){
            this.checkIfLoginDone();
          }
        })
  }

  checkIfLoginDone(){
    console.log("checkIfLoginDone checkauthfile_time:"+this.checkauthfile_time);
    if(this.checkauthfile_time <= 0){
      return;
    }
    this.checkauthfile_time -= 1;
    setTimeout(() => {
      this.getAuthByQrCode();
    }, 3000);
  }

  renderQrCodeView(){
    if(this.state.auth != null){
      return(<div/>);
    }
    if(this.state.qrcode == null){
      return(
        <h2 style={{margin:24}}>正在加载二维码...</h2>
      );
    }
    let url = 'http://storage.jiwenleishi.com/platform/websitelogin/qrcode/'+this.state.qrcode;
    return(
      <div>
        <h3 style={{fontSize:24,margin:24,display:"flex"}}>扫描登录后上传excel文件</h3>
        <QRCode 
        renderAs="svg"
        size={256}
        value={url} />
        <Button type="primary" onClick={()=>this.getAuthByQrCode()}>已经扫码确认</Button>
      </div>
      
    );
  }

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    // fileList.forEach(file => {
    //   formData.append('upload[]', file);
    // });
    formData.append('auth', this.state.auth);
    formData.append('upload', fileList[0]);
    this.setState({
      uploading: true,
    });


    reqwest({
      url: "/index.php?m=website&c=qstexcel&a=uploadexcel",
      method: 'post',
      processData: false,
      data: formData,
      success: (responseData) => {
        console.log("handleUpload reqwest responseData:",responseData);
        let response_obj = JSON.parse(responseData);
        if(response_obj.code ==100){
          this.setState({
            fileList: [],
            uploading: false,
          });
          message.success('上传成功');
        }else{
          message.success('上传失败:'+response_obj.message);
        }
        
      },
      error: () => {
        this.setState({
          uploading: false,
        });
        message.error('上传失败.');
      },
    });
  };

  deleteTempAuthFile(){

    let formData = new FormData();
    formData.append("auth",global.auth);
    formData.append("authfilecode",this.state.qrcode);
    let opts = {
        method:"POST",
        body:formData
    };
    fetch("/index.php?m=website&c=userauth&a=deletetemp",opts)
        .then((response) => response.json())
        .then((responseData) => {
            if(responseData.code == 100){

            }else{

            }

        })
        .catch((error) => {
            alert(error)
        })
    }

  renderSelectFileUploadView(){
    if(this.state.auth == null){
      return(<div/>);
    }
    const { uploading, fileList } = this.state;
    const uploadprops = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    return(
      <div  style={{padding:10}}>
        <Upload {...uploadprops}>
          <Button icon={"UploadOutline"}>选择编辑好的excel文件</Button>
        </Upload>
        <div>
          <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
          
        >
          {uploading ? "正在上传。。。" : '开始上传'}
        </Button>
        </div>
      </div>
    );
  }

  renderUserInfoView(){
    if(this.state.auth == null){
      return(<div/>);
    }
    return(
      <div width={800} min-eight={2000} style={{display:"flex",flexDirection:"column",alignItems:"center",backgroundColor:"#8FBC8F",padding:20}}>
        <Avatar shape="square" size={64} src={"http://storage.jiwenleishi.com/"+this.state.head} />
        <h3 style={{margin:8}}>{this.state.username}</h3>
      </div>
    );
  }

  renderTemplateView(){
    return (
      <div style={{padding:10}}>
        <a download="https://storage.jiwenleishi.com/platform/web/bathdealqst/bathprocessqst.xls" href="https://storage.jiwenleishi.com/platform/web/bathdealqst/bathprocessqst.xls">下载模版excel文件</a>
      </div>
    );
  }
      
  
  render() {
    const children = [
      <Content1
          id="Content1_0"
          key="Content1_0"
          dataSource={Content1DataSource}
          isMobile={this.state.isMobile}
      />
    ];
    return (
      <div
        // className="templates-wrapper"
        style={{alignItems:"center",flexDirection:"column",padding:2}}
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {/*this.state.show && children*/}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
        <div style={{flexDirection:"row",display:"flex",margin:4}} min-height={4000}>
          {this.renderUserInfoView()}
          <div style={{margin:80}}>
            {this.renderQrCodeView()}
            {this.renderTemplateView()}
            {this.renderSelectFileUploadView()}
          </div>
        </div>
        
      </div>
    );
  }
}
