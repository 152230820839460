import React from 'react';

export const Content1DataSource = {
  wrapper: { className: 'home-page-wrapper about-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 8, xs: 24 },
  img: {
    children: 'http://slako-buildqst.stor.sinaapp.com/platform/img/website/jiwenleishishimo-2.png',
  },
  textWrapper: { className: 'content1-text', md: 16, xs: 24 },
  title: { className: 'content1-title', children: '永远免费的效率类学习软件' },
  content: {
    className: 'content1-content',
    children:
        '扫描登录后上传excel文件。',
  },
};


