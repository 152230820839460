import React from 'react';

export const Content10DataSource = {
  wrapper: { className: 'content6-wrapper' },

  listdata: {
    className: 'content1-content',
    children:[{title:'abc'},{title:'abc'},{title:'abc'}],
  },
};


