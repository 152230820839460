/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import {
  Content10DataSource
} from './data.source';
import '../less/antMotionStyle.less';
import Content6 from './Content6';
import {Teams20DataSource} from "../Example/data.source";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

export default class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
    this.dofetch_articlelist();
  }
  converData(webdata){

    let newData = Content10DataSource;
    webdata.forEach((item,index)=>{
      //item.href = "http://slako-buildqst.stor.sinaapp.com/platform/slakoarticle/zh/"+item.id+"/markdown.text";
      item.href = './articlemarkdown/'+item.id;
    });
    newData.listdata.children = webdata;
    return newData;
  }

  dofetch_articlelist(){

    let url = "http://slako-buildqst.stor.sinaapp.com/platform/slakoarticle/list.json";
    let opts = {
      method:"GET"
    };
    fetch(url,opts)
        .then((response) => response.json())
        .then((responseData) => {

          this.setState({
            articlelist:this.converData(responseData)
          });
          //alert("hello");
        })
        .catch((error) => {
          //alert("eerr  hello23"+error);
        })
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Content6
          id="Content6_0"
          key="Content6_0"
          dataSource={this.state.articlelist?this.state.articlelist:Content10DataSource}
          isMobile={this.state.isMobile}
      />
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
